import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Image from "../components/image"
// import HeroPage from "../components/views/hero-page"

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="Our Story" img={`/img/slider1.jpg`} />

      <div style={{ marginTop: "100px", marginBottom: "50px" }}>
        <Container>
          <Row>
            <Col>
              <h3 className="section-title mb-5">My Story</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="mb-5 text-center">
                My name is Xavier Williams and I am both a business owner and
                entrepreneur here in San Francisco.
              </h3>

              <div className="d-flex justify-content-center">
                <Image
                  src="xavier2.jpg"
                  style={{
                    width: "300px",
                    marginBottom: "30px",
                  }}
                  alt={data.site.siteMetadata.title}
                />
              </div>
              <div className="text-center">
                <p>
                  I am native to the Bay Area, having grown up in Oakland before
                  moving to San Francisco in 1990 where I started a family and
                  built my business, 3 Mil Wil Hair Salon.
                </p>
                <p>
                  I am a renowned cosmetologist with over 30 years of hair
                  styling experience under my belt.
                </p>
                <p>
                  Over the years I have worked with diverse populations,
                  providing an array of cosmetology and barbering services to my
                  clients.
                </p>
                <p>
                  For as long as I could remember, I have always loved hair and
                  I am thankful that I have had the opportunity to share my
                  talents with my clients for almost 40 years.
                </p>
                <p>
                  In more recent years my brand, 3 Mil Wil Hair Salon, has taken
                  off and there has been an uptick in business and new clients.
                </p>
                <p>
                  I am both happy and excited to meet new clients and, more
                  importantly, provide this platform on which you can learn more
                  about my craft.
                </p>
              </div>
              <br />
              <h3 className="text-center">
                Checking In Checking Out
                <br />
                Making It Happen
                <br />
                Without A Doubt
              </h3>
              <div className="d-flex justify-content-center">
                <Image
                  src="wide.png"
                  style={{
                    width: "200px",
                    marginBottom: "30px",
                    marginTop: "30px",
                  }}
                  alt={data.site.siteMetadata.title}
                />
              </div>
              <br />
              <div className="d-flex justify-content-center">
                <Image
                  src="3milwil-special-dark.png"
                  style={{
                    width: "500px",
                  }}
                  alt={data.site.siteMetadata.title}
                />
              </div>
              <br />
              <div className="d-flex justify-content-center">
                <Image
                  src="belt.png"
                  alt={data.site.siteMetadata.title}
                  style={{
                    width: "300px",
                  }}
                />
              </div>
              <div className="text-center mt-5">
                <h3>
                  I look forward to connecting with you and please reach me out
                  if you have any questions about the services I offered.
                </h3>
                <a
                  className="btn btn-primary btn-lg mt-3"
                  href={`tel:${data.site.siteMetadata.phone}`}
                >
                  {data.site.siteMetadata.phone}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query OurStoryPageQuery {
    site {
      siteMetadata {
        title
        description
        address
        phone
      }
    }
  }
`
